.title__container {
    width: fit-content;
    margin: 2rem 0 0 2rem;
    margin-bottom: 2.5rem;

    display: flex;
}

.title {
    width: fit-content;

    color: var(--f20);
}

.dropdown__container {
    margin: 0 1.5em;
}

.dropdown__trigger {
    display: flex;
    justify-content: space-between;

    line-height: normal;

    font-size: 36px;

    min-width: 200px;
    width: fit-content;
    height: fit-content;

    padding: 0;
    padding-bottom: 0.25em;

    color: var(--f40);

    border: none;
    border-radius: 0;
    border-bottom: 1px solid var(--f60);

    background-color: transparent;

    &:hover {        
        background-color: transparent;

        .dropdown__icon {
            fill: var(--f100);
        }
    }
}

.dropdown__body {
    top: calc(100% + 10px);
    left: 0;
}

.dropdown__icon {
    margin: auto 0;
    margin-left: 0.25em;
    fill: var(--f40);

    transition: fill 0.2s ease-in-out;
}

@media (max-width: 760px) {
    .title__container {
        flex-direction: column;
    }
}