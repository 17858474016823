.input {
    position: relative;
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--f60);
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid var(--f10);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    outline: none;
}

.file__input {
    cursor: pointer;

    position: relative;
    background-color: var(--f10);

    width: 100%;
    height: 2.5rem;

    input[type="file"] {
        display: none;
    }

    transition: 0.2s ease-in-out;

    .upload__text {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;

        font-weight: 200;
        font-size: 0.85rem;

        color: var(--f40);
        transition: 0.2s ease-in-out;
    }

    &:hover {
        background-color: var(--f20);

        .upload__text {
            color: var(--f80);
        }
    }
}

.date__input, .time__input {
    position: relative;
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--f60);
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid var(--f10);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    text-align: center;
}

.date__input::-webkit-calendar-picker-indicator, .time__input::-webkit-calendar-picker-indicator {
    cursor: pointer;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    opacity: 0;
}

.dropdown__container {
    width: 100%;
}

.dropdown__trigger {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;

    color: var(--f40);

    border: 1px solid var(--f10);
    border-radius: 0;
    // border-bottom: 1px solid var(--f60);

    background-color: transparent;

    &:hover {        
        background-color: transparent;

        .dropdown__icon {
            fill: var(--f100);
        }
    }
}

.dropdown__body {
    width: calc(100% + 10px);
    /*height: calc(4 * (100% + 2px));*/
    height: auto;
    
    top: calc(100% + 10px);
    left: 0;

    overflow-y: scroll;
}

.dropdown__icon {
    margin: auto 0;
    margin-left: 0.25em;
    fill: var(--f40);

    transition: fill 0.2s ease-in-out;
}

.disabled {
    opacity: 0.3;
    cursor: not-allowed !important;
}

.file__input {
    cursor: pointer;

    position: relative;
    background-color: var(--f10);

    width: 100%;
    height: 2.5rem;

    input[type="file"] {
        display: none;
    }

    transition: 0.2s ease-in-out;

    .upload__text {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;

        font-weight: 200;
        font-size: 0.85rem;

        color: var(--f40);
        transition: 0.2s ease-in-out;
    }

    &:hover {
        background-color: var(--f20);

        .upload__text {
            color: var(--f80);
        }
    }
}

.checkbox__input {
    height: 1.5em;
    width: 10%;
}

