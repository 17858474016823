.card__container {
    display: flex;
}

.user__table__container, .user__table {
    margin: 0 auto;

    width: 70%;
    border-collapse: collapse;

    * {
        border: 1px solid var(--f10);
    }

    thead * {
        color: var(--f90);

        font-weight: normal;
    }

    tr {
        td {
            a {
                border: none;
            }

            padding: 0.5rem 0.5rem;

            &:last-child {
                * {
                    margin-left: 0.5rem;
                
                    &:first-child {
                        margin-left: 0;
                    }
                }

                display: flex;
                justify-content: center;

                border: 1px solid transparent;
            }
        }

        &:nth-child(even) {
            background-color: var(--f10);
        }
    }
}

.user__table {
    width: 100%;
}

.add__user__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(400px + 10vw);

    margin-top: 1rem;
}

.add__user__button {
    margin-top: 0.5rem;
    width: 100%;

    background-color: var(--f100);

    &:hover {
        background-color: var(--f60);
    }
}

.input__container {
    width: 100%;
    margin-bottom: 0.5rem;
}

.add__user__modal__buttons {
    margin-top: 1rem;

    display: flex;
    justify-content: space-between;

}

.add__user__cancel {
    color: var(--f40);
    background-color: var(--f10);

    &:hover {
        color: var(--f100);
        background-color: var(--f20);
    }
}

.file__input {
    cursor: pointer;

    position: relative;
    background-color: var(--f10);

    width: 100%;
    height: 2.5rem;

    input[type="file"] {
        display: none;
    }

    transition: 0.2s ease-in-out;

    .upload__text {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;

        font-weight: 200;
        font-size: 0.85rem;

        color: var(--f40);
        transition: 0.2s ease-in-out;
    }

    &:hover {
        background-color: var(--f20);

        .upload__text {
            color: var(--f80);
        }
    }
}

.upload__container {
    display: flex;
    justify-content: space-between;
}

.multiselect__container {
    display: flex;
    flex-direction: row; 

    justify-content: space-between;
}

.multiselect__button {
    width: calc((100% / 3) - 0.25rem);
    height: 2.5rem;

    color: var(--f40);
    background-color: var(--f10);

    transition: 0.15s ease-in-out;

    &:hover {
        color: var(--f80);
        background-color: var(--f20);
    }
}

.active {
    color: var(--f100);
    background-color: var(--f20);

    outline: red;
}

.add__modal__button {
    width: 100%;
}

.underline {
    text-decoration: underline;
}