@import '../../styles/variables.scss';

.event__container {
    display: flex;
}

.event {
    margin-right: 1rem;
    margin-bottom: 1rem;

    position: relative;
    width: 100%;

    transition: $transition;
    transition: z-index 0;

    &:hover {
        z-index: 1;

        transform: 10%;
        .event__background {
            opacity: 0.4;
        }
    }
}

.event__background {
    position: absolute;
    
    width: calc(100%);
    height: calc(100%);

    display: inline-block;
    background: linear-gradient(0deg, rgba(27,27,27,1) 10%, rgba(27,27,27,0) 100%) 0 0 / cover no-repeat;

    opacity: 0.75;

    transition: $transition;
}

.event__cover {
    // width: calc((80vw) / 3);
    min-height: 200px;
    height: calc((80vw) / 5);
    max-width: 100%;
    // max-height: 100%;

    object-fit: cover;
    box-shadow: var(--shadow40);
}

.event__info {
    margin: 0 1rem;
    position: absolute;

    bottom: 1rem;
    z-index: 5;
}

.left {
    left: 0;
    text-align: left;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
}

.right {
    right: 0;
    text-align: right;
}


.label__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.image__container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    z-index: -1;
}

.event__label {
    padding: 0;

    margin-bottom: 0.5rem;

    &:last-of-type {
        margin-bottom: 0;
    }
}

.welcome {
    font-size: 2.5rem;
    margin-bottom: 3rem;
}

@media (min-width: 761px) and (max-width: 1280px) {
    .right {
        margin-bottom: 0rem;
    }
}

@media (max-width: 760px) {
    .event__info {
        position: relative;
    }

    .event__label {
        white-space: break-spaces;
    
        margin-bottom: 0.5rem;
    
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .right {
        right: 0;
        text-align: left;
        margin-bottom: 1rem;
        margin-top: 2rem;
    }


}