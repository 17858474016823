@import '../../../styles/variables.scss';

.card {
    padding: 0.5rem;

    border: 1px solid var(--f10);

    position: relative;
    width: 100%;
    height: fit-content;

    transition: $transition;

    &:hover {
        transform: translateY(-0.25rem);
        box-shadow: var(--shadow40);
    }
}