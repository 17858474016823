.notifications {
    z-index: 9999;

    position: fixed;
    bottom: 0;
    right: 0;

    padding: 1rem;

    display: flex;
    // flex-direction: column;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;

    min-width: 300px;
    width: 350px;

    height: fit-content;
}

.notification__container {
    position: relative;
    width: 100%;

    margin: 0.25rem;
    padding: 0.8rem;

    display: flex;
    flex-direction: row;

    border: 1px solid var(--f10);
    border-left: 10px solid var(--f10);
    border-radius: 0.5rem;

    background-color: var(--b100);
    box-shadow: var(--shadow10);

    transition: border 0.5s;

    &:first-of-type {
        margin-bottom: 0;
    }
}

.notification__inner__container {
    position: relative;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: left;
}

.notification {
    font-size: 0.9rem;

    width: 100%;
    height: fit-content;

    padding: 0 0.5rem;

    transition: all 0.3s ease-in-out;
}

.label {
    text-transform: capitalize;
    font-size: 0.7rem;
}

.success {
    border-left: 10px solid #2A9D8F;
}

.error {
    border-left: 10px solid #E74C3C;
}

.icon {
    width: 24px;

    display: flex;
    justify-content: center;
    align-items: center;

    * {
        fill: var(--f60);
    }
}

.loader__semicircle {
    position: absolute;

    width: 24px;
    height: 24px;

    border: 2px solid var(--f10);
    border-top-color: var(--f60);
    border-radius: 24px;
}

.expiry {
    position: absolute;
    background-color: var(--f10);

    bottom: 0;
    right: -8px;

    width: 2px;
    height: 0px;

    transition: height 0.05s linear;
}