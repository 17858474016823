.message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
}

.button {
    width: fit-content;

    margin: 0.5rem auto 0;
    font-size: 0.8rem;
}