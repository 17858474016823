.label {
    display: inline-block;
    padding: 0.2rem 0.5rem;
    font-size: 0.8rem;
    line-height: 1;
    text-align: left;
    white-space: nowrap;
    border-radius: 0.25rem;
    color: var(--f40);
    max-width: 100%;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

.center {
    text-align: center;
}