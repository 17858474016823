.event__title {
    padding: 0.5rem;
    padding-bottom: 0;
}

.network {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    margin-bottom: 2rem;
}

.network__dropdown {
    width: calc(30vw);
    max-width: 250px;
}