@import '../../styles/variables.scss';

.broken {
    height: 200px !important;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    text-align: center;
}

.token {
    cursor: pointer;
    padding: 0.5rem;

    position: relative;
    width: 100%;
    height: fit-content;

    transition: $transition;

    &:hover {
        background-color: var(--f5);
        border-radius: 0.5rem;

        transform: translateY(-0.25rem);
        box-shadow: var(--shadow40);

        .edit__icon {
            opacity: 1;
            visibility: visible;
        }

        .event__cover {
            box-shadow: var(--shadow40);   
        }
    }
}

.token.previously__minted {
    width: 22%;
}

@media screen and (max-width: 991px) {
    .token.previously__minted {
        width: 100%;
    }
}

.edit__container {
    position: relative;

    display: flex;
    flex-direction: column;

    width: 100%;

    path {

        fill: var(--f80);
    }
}
.edit__icon {
    cursor: pointer;

    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    opacity: 0;
    visibility: hidden;

    transition: 0.2s ease-in-out;

    width: fit-content;
    height: fit-content;
    padding: 1em;

    border: 1px solid var(--f10);
    border-radius: 50%;

    background: var(--b80);

    &:hover {
        box-shadow: var(--shadow40);
        background: var(--b100);
        path {
            fill: var(--f100);
        }
    }
}

.event__cover {
    max-width: 100%;
    height: 250px;
    display: block;
    margin-left: auto;
    margin-right: auto;

    object-fit: contain;
}

.event__title {
    padding: 0.5rem;
    padding-bottom: 0;

    font-size: 1rem;
}

.artist__name_event_view {
    padding: 0.5rem;
    padding-bottom: 0;

    font-size: larger;
}

.add__container {
    cursor: pointer;

    &:hover {
        background-color: var(--f10);
    }
}


.label__container {
    padding-top: 0.5rem;

    width: 100%;
    height: fit-content;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.label__truncated {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.1rem;
    color: #BBB;
}

.auction__history {
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    font-size: 1rem;
    border-top: 1px solid var(--f10);
}

.countdown {
    width: 100%;
    height: fit-content;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 1rem;

    background-color: var(--f100);
    color: var(--b100);

    padding: 0.5rem 0;
}

.bid__button {
    padding: 0.25rem 1rem;
    background-color: var(--f100);
    color: var(--b100);

    height: fit-content;

    margin: auto 1rem;
    border-radius: 0;
}

.buy__button {
    cursor: pointer;
    border: none;
    padding: 0.25rem 1rem;
    color: #edf2f4;
    color: var(--b100);
    background-color: var(--f100);
    transition: .3s ease-out;
    margin: auto 1rem;
    font-size: 0.85rem;
}

.buy__button:hover {
    background-color: var(--f80);
}

.info__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;

    width: 100%
}

.placeholder {
    width: 300px;
    height: 300px;

    position: relative;
}

.loader__container {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
}

.loader {
    width: 24px;
    height: 24px;

    border: 2px solid var(--f10);
    border-top-color: var(--f60);
    border-radius: 24px;
}

.mintpreview {
    max-height: 18rem;
}

a {
    text-decoration: none;
}

.token__devider{
    display:inline-block;
}

.dummy__token__info{
    padding-top: 8rem;
    padding-left: 10rem;
    position: absolute;
    text-align: left;
}
@media (max-width: 760px) {
    .token__devider{
        display:block;
        padding: 0;
        margin: 0;
    }
    .dummy__token__info{
        text-align: left;
        position: relative;
        display:inline-block;
        padding: 2rem;
    }
    .artist__title__box{
        padding: 0;
        margin: 0;
    }
    .artist__info__box__name{
        font-size: medium;
    }
    .artist__info__box__title{
        font-size: small;
    }
    .artist__info__box__two{
        padding-right: 1.5rem;
        margin: 0;
        display:inline-block;
        font-size: small;
    }
    .token__info__box{
        padding: 0;
        margin: 0;
        display:inline-block;
    }
    .exhibition__token{
        padding: 0;
        margin: 0;
    }
    .exhibition__edit__container{
        display:inline-block;
    }
    .exhibition__token__cover{
        width: 100% !important;
        height: 20% !important;
        padding-bottom: 1rem;
        margin: 0;
    }
    .img__cover{
        width: 'calc(0.5 * 110vw)';
    }
}
.artist__info__event__view{
    padding-bottom: 0;

    font-size: 1.1rem;
}
.artist__title__box{
    font-size: 1.8rem;

}
.token__info__box{
    font-family: 'OCR A Extended';
    font-size: 1.4rem;

}

.dummy__buy__button{
    cursor: pointer;
    border: none;
    padding: 0.25rem 1rem;
    color: #edf2f4;
    color: var(--b100);
    background-color: var(--f100);
    transition: .3s ease-out;
    font-size: 0.85rem;
    width: 8rem;
}

.buy__button:hover {
    background-color: var(--f80);
}

.artist__info__box__name{
    font-family: 'Space Grotesk';
    font-weight: bold;
}

.artist__info__box__title{
    font-family: 'OCR A Extended';
    font-weight: italic;
}
.artist__info__box__two{
    padding-top: 0.5rem;
    font-family: 'OCR A Extended';
}

.exhibition__token__cover {
    width: 500px;
    height: 500px;
    display: block;
    margin-left: auto;
    margin-right: auto;

    object-fit: contain;
}

.exhibition__edit__container{
    padding-left: 1rem;
    position: relative;

    display: flex;
    flex-direction: column;

    width: 100%;

    path {

        fill: var(--f80);
    }
}

.exhibition__token {
    cursor: pointer;
    padding: 1rem;
    margin: 1rem;

    position: relative;
    width: 100%;
    height: fit-content;

    transition: $transition;

    &:hover {
        background-color: var(--f5);
        border-radius: 0.5rem;

        transform: translateY(-0.25rem);
        box-shadow: var(--shadow40);

        .edit__icon {
            opacity: 1;
            visibility: visible;
        }

        .event__cover {
            box-shadow: var(--shadow40);   
        }
    }
}
.video__player{
    height:20rem;
}
.img__cover{
    width: 'calc(0.5 * 75vw)';
}