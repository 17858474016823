:root {
    --f5: rgba(255, 255, 255, 0.05);
    --f10: rgba(255, 255, 255, 0.1);
    --f20: rgba(255, 255, 255, 0.2);
    --f40: rgba(255, 255, 255, 0.4);
    --f60: rgba(255, 255, 255, 0.6);
    --f80: rgba(255, 255, 255, 0.8);
    --f90: rgba(255, 255, 255, 0.9);
    --f100: rgba(255, 255, 255, 1);

    --b5: rgba(38, 39, 41, 0.05);
    --b10: rgba(38, 39, 41, 0.1);
    --b20: rgba(38, 39, 41, 0.2);
    --b40: rgba(38, 39, 41, 0.4);
    --b60: rgba(38, 39, 41, 0.6);
    --b80: rgba(38, 39, 41, 0.8);
    --b90: rgba(38, 39, 41, 0.9);
    --b100: rgba(38, 39, 41, 1);

    --shadow5: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    --shadow10: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    --shadow20: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    --shadow40: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

$transition: 0.2s;