@import '../../../styles/variables.scss';

.card {
    cursor: pointer;

    height: fit-content;
    width: 100%;

    padding: 1rem;
    color: var(--f40);

    &:hover {
        color: var(--f100);

        .card__icon {
            fill: var(--f90);
        }
    }
}

.label {
    font-size: 1rem;
    padding: 0;
}

.card__icon {
    display: flex;
    width: fit-content;
    height: fit-content;

    padding: 50px 0 0;
    margin-left: auto; 

    fill: var(--f40);
    transition: $transition;
}