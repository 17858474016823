.buttons__container {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

    padding: 1em 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
}

.inner__buttons__container {
    position: relative;

    width: calc(400px + 4vw + 1em);
    padding: 1em;

    border-radius: 1em;
}

.log__out {
    margin-top: .5em;

    color: var(--f60);
    background-color: transparent;
    border: 1px solid var(--f10);

    width: 100%;

    &:hover {
        color: var(--f100);
        background-color: var(--f10);    
    }
}

.connect__label {
    width: 100%;

    font-size: medium !important;
    margin-bottom: 1em;
}