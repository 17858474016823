@import '../../styles/variables.scss';

model-viewer, video, audio, iframe {
    border: none;
    width: 100%;
    margin: 0 auto;
    
    max-height: calc(100% - 70px);
    max-width: 100%;
}

@media (max-width: 760px) {   
  iframe {
    min-height: 35vh;
    width: 100% !important;
    height: 100% !important 
 }
}

.fill {
    height: 100% !important;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 25px;
}

.block {
    position: absolute;
    width: 100%;
    height: 100%;

    z-index: 1;
}

.container {
    width: 100%;
    height: fit-content;

    display: flex;
    flex-direction: column;
}

.audio__player {
    display: none;
}

.controls {
    cursor: pointer;

    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    fill: var(--f40);
}

.controls__fullscreen, .controls__volume, .controls__play {
    transition: $transition;

    &:hover {
        background-color: var(--f5);
        border-radius: 0.5rem;

        fill: var(--f90);
    }
}

.controls__volume, .controls__fullscreen {
    height: fit-content;
    width: fit-content;

    padding: 0.5rem;
}