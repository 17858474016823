@import '../../styles/variables.scss';

.event__wrapper {
    padding-bottom: 6rem;
}

@media (max-width: 760px) {
    .link__wrapper {
        text-decoration: none;
    }
}