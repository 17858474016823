.search__container {
    display: flex;
    gap: 1em;
    margin-bottom: 3em;
}

.filter__dropdown {
    width: 25%
}

@media (max-width: 760px) {
    .search__container {
        flex-direction: column;
    }

    .filter__dropdown {
        width: 100%;
    }
}