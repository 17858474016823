@font-face {
    font-family: 'OCR A Extended';
    src: url('fonts/OCR-A-Std-Regular.eot') format('eot');
    src: local('OCR A Extended'),
        url('fonts/OCR-A-Std-Regular.woff') format('woff'),
        url('fonts/OCR-A-Std-Regular.woff2') format('woff2'),
        url('fonts/OCR-A-Std-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Space Grotesk';
    src: local('Space Grotesk'),
        url('fonts/SpaceGrotesk.ttf') format('truetype');
}