.button {
    cursor: pointer;
    border: none;
    border-radius: 5px;
    padding: .8em 2em;
    color: #edf2f4;
    color: var(--b100);
    background-color: #212529;
    background-color: var(--f100);
    transition: .3s ease-out;

    font-size: 0.85rem;
}

.button:hover {
    background-color: var(--f80);
}

.disabled {
    cursor: not-allowed;
    background-color: var(--f20);
}
