.preview__content,
.loading__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    align-items: center;
}

.preview__content p {
    margin-top: 3rem;
    font-size: 1.3rem;
    text-align: center;
}

.loading__content p {
    margin-top: 3rem;
    font-size: 1rem;
    text-align: center;
}

.social__share {
    border: 1px solid var(--f40);
    border-radius: 0.25rem;
    flex-wrap: wrap;
    margin-top: 1.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.social__share__box {
    &:nth-child(even) {
        border-left: 1px solid var(--f40);
    }

    padding: 0.5rem;
    text-align: center;

    display: flex;
    flex-direction: column;
}

.social__share__text{
    vertical-align: middle;
    display: inline-block;

}