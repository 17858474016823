.container {
    width: 80vw;
    margin: 0 auto;
    
    padding-bottom: 3rem;
    padding-top: 3rem;
}

@media (max-width: 760px) {
    .container{
        width: 90vw;
    }
}