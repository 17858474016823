.message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.fields__container {
    width: calc(80vw * 0.5);
}

.multiselect__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-bottom: 1rem;
}

.multiselect__button {
    width: calc((100% / 2) - 0.25rem);
    height: 2.5rem;

    color: var(--f40);
    background-color: var(--f10);

    transition: 0.15s ease-in-out;

    &:hover {
        color: var(--f80);
        background-color: var(--f20);
    }
}

.active {
    color: var(--f100);
    background-color: var(--f20);
}

.field {
    position: relative;
    margin-bottom: 0.25rem;

    display: flex;
    flex-direction: column;
}

.split {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .split__el {
        width: calc((100% / 2) - 0.25rem);
    }
}

.single__network {
    position: absolute;
    right: 2rem;

    top: 50%;
    transform: translateY(-50%);
}

.disabled {
    opacity: 0.3;
}

.mint__button {
    margin-top: 1rem;

    width: 100%;
}

.textarea {
    position: relative;
    display: block;
    width: 100%;
    height: calc((1.5em + 0.75rem + 2px) * 5);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--f60);
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid var(--f10);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    outline: none;

    resize: vertical;
}

.container {
    display: flex;
    position: relative;
}

.date__container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    .picker__input {
        margin-right: 0.5rem;
    }
}

.mock__container {
    width: calc(80vw * 0.5);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mock__inner__container {
    position: fixed;

    width: fit-content;
    height: fit-content;

    margin-bottom: 150px;
}