.modal__container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;

    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    position: absolute;
    
    width: 80%;
    max-width: 500px;
    border-radius: 0.5rem;
    padding: 1rem;

    background-color: var(--b100);

    box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.5);
}

.modal__wrapper {
    margin-top: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.modal__spacer {
    height: 1rem;   
}

.close__modal {
    cursor: pointer;

    position: absolute;
    top: 0;
    right: 0;

    width: 2rem;
    height: 2rem;
    z-index: 1;   

    display: flex;
    justify-content: center;
    align-items: center;

    border: none;
    outline: none;

    background-color: transparent;

    svg {
        fill: var(--f90);
    }
}