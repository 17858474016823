@import '../../../../styles/variables.scss';

.broken {
    height: 200px !important;

    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;
}

.token {
    padding: 0.5rem;

    border: 1px solid var(--f10);

    position: relative;
    width: fit-content;
    height: fit-content;

    transition: $transition;

    &:hover {
        transform: translateY(-0.25rem);
        box-shadow: var(--shadow40);

        .edit__icon {
            opacity: 1;
            visibility: visible;
        }
    }
}

.edit__container {
    position: relative;

    display: flex;
    flex-direction: column;

    width: 100%;

    path {

        fill: var(--f80);
    }
}

.edit__icon {
    cursor: pointer;

    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    opacity: 0;
    visibility: hidden;

    transition: 0.2s ease-in-out;

    width: fit-content;
    height: fit-content;
    padding: 1em;

    border: 1px solid var(--f10);
    border-radius: 50%;

    background: var(--b80);

    &:hover {
        box-shadow: var(--shadow40);
        background: var(--b100);
        path {
            fill: var(--f100);
        }
    }
}

.event__cover {
    max-width: 100%;
    max-height: 100%;

    object-fit: cover;
}

.event__title {
    padding: 0.5rem;
    padding-bottom: 0;
}

.add__container {
    cursor: pointer;

    &:hover {
        background-color: var(--f10);
    }
}

.event__cover {
    max-width: 100%;
    max-height: 100%;

    object-fit: cover;
}

.label__container {
    width: 100%;
    height: fit-content;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.remove {
    margin-top: 1rem;

    text-decoration: underline;
    cursor: pointer;

    width: 100%;
}