.metamask {
    position: relative;
}

.network__text {
    position: absolute;

    right: 1.5em;
    font-size: 12px;

    font-weight: 200;
    color: var(--f40);
}