.message {
    margin-top: 0.5rem !important;
}

.fields__container {
    width: calc(80vw * 0.5);
}

.active {
    color: var(--f100);
    background-color: var(--f20);
}

.field {
    position: relative;
    margin-bottom: 0.25rem;

    display: flex;
    flex-direction: column;
}

.split {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .split__el {
        width: calc((100% / 2) - 0.25rem);
    }
}

.disabled {
    opacity: 0.3;
}

.mint__button {
    margin-top: 1rem;

    width: 100%;
}


.container {
    display: flex;
    position: relative;
}

@media (max-width: 1280px) {
    .container {
        flex-direction: column;
    }

    .fields__container {
        width: 100%;
    }

}