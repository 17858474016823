.loader {
    position: absolute;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
    z-index: 1;
}

.logo {
    display: flex;
    align-items: center;
    justify-content: center;

    transform-origin: center !important;
    transform: translateY(0) rotateY(0deg);
    animation: loader 2s ease-out infinite;

    border: 1px solid var(--f10);
    border-radius: 50%;

    width: 56px;
    height: 56px;

    path {
        fill: var(--f90);
    }
}

@keyframes loader {
    50% {
        transform: translateY(-3px) rotateY(180deg);
    }
    100% {
        transform: translateY(0px) rotateY(360deg);
    }
}