.header {
    position: fixed;
    top: 0;
    z-index: 1000;

    width: 100vw;
    padding: 0.5em 4em;

    display: flex;
    align-items: center;
    justify-content: space-between;
    
    border-bottom: 1px solid var(--f20);
    background-color: var(--b90);
}

@media (max-width: 760px) {
    .header{
        padding: 0.5em 1em;
    }
}
.logo__container {
    text-decoration: none;
}