@import '../../../styles/variables.scss';

.event {
    padding: 0.5rem;

    border: 1px solid var(--f10);

    position: relative;
    width: fit-content;
    height: fit-content;

    transition: $transition;

    &:hover {
        transform: translateY(-0.25rem);
        box-shadow: var(--shadow40);

        .edit__icon {
            opacity: 1;
            visibility: visible;
        }
    }
}

.edit__container {
    position: relative;

    display: flex;
    flex-direction: column;

    width: 100%;

    path {

        fill: var(--f80);
    }
}

.edit__icon {
    cursor: pointer;

    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    opacity: 0;
    visibility: hidden;

    transition: 0.2s ease-in-out;

    width: fit-content;
    height: fit-content;
    padding: 1em;

    border: 1px solid var(--f10);
    border-radius: 50%;

    background: var(--b80);

    &:hover {
        box-shadow: var(--shadow40);
        background: var(--b100);
        path {
            fill: var(--f100);
        }
    }
}

.event__cover {
    max-width: 100%;
    max-height: 100%;

    object-fit: cover;
}

.event__title {
    padding: 0.5rem;
}

.add__event {
    padding: 0.5rem;
    
    width: fit-content;
    height: fit-content;
}

.add__container {
    cursor: pointer;

    &:hover {
        background-color: var(--f10);
    }
}

.cover__container {
    display: flex;
    justify-content: space-between;
}

.underline {
    width: fit-content;
    height: fit-content;
    
    text-decoration: underline;
}

.label__container {
    display: flex;
    flex-direction: column;
}

.bottom__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.remove__container {
    width: fit-content;
    height: fit-content;
}

.remove {
    cursor: pointer;
    text-decoration: underline;

    transition: $transition;

    &:hover {
        color: var(--f100);
    }
}

.date__container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    .picker__input {
        margin-right: 0.5rem;
    }
}

.publish__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}