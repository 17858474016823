.container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;
}

.button {
    margin-top: 2rem;
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
}