.dropdown__container {
    position: relative;

    width: fit-content;
    height: fit-content;

    z-index: 100;
}

.dropdown {
    height: fit-content;
    width: 100%;
    //max-width: calc(250px + 1.5vw);
    max-height: 6em;
    position: relative;

    border-top: 1px solid var(--f20);
}

.dropdown__spacer {
    position: relative;

    padding-bottom: 1rem;
    border-bottom: 1px solid var(--b100);

    cursor: default;
}

.dropdown__spacer::after {
    content: '';

    position: absolute;
    bottom: -1px;
    left: 0;

    height: 1px;
    width: 100%;

    background-color: var(--f20);
}

.dropdown__items {
    position: absolute;
    
    width: fit-content;

    top: 100%;
    right: 0;
}

.item__container {
    background-color: var(--b100);
    display: flex;
}

.dropdown__item {
    border-radius: 0;

    position: relative;
    width: 100%;

    border: 1px solid var(--f20);
    border-top: 0;
    background-color: var(--b100);

    color: var(--f40);
    text-align: left;

    &:hover {
        background-color: var(--f10);
        color: var(--f100);
    }

    white-space: nowrap;
}

.dropdown__trigger {
    position: relative;
    width: 100%;

    border: 1px solid var(--f20);
    background-color: var(--b100);

    color: var(--f40);
    text-align: left;

    &:hover {
        background-color: var(--f10);
        color: var(--f100);
    }
}