.button__container {
    position: relative;
    margin-bottom: .5em;
}

.button {
    padding: 1em 1.5em;
    font-size: 16px;

    color: var(--f60);
    background-color: transparent;
    border: 1px solid var(--f10);

    width: 100%;
}

.button:hover {
    color: var(--f100);
    background-color: var(--f10);
}

.logo__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.logo {
    margin-right: 1em;

    height: 32px;
}

.connected {
    background-color: rgba(42, 157, 143, 0.1);
    color: var(--f60)
}

.connected:hover {
    background-color: rgba(42, 157, 143, 0.15);
    color: var(--f100)
}

.connected__label {
    cursor: default;

    background-color: #2a9d8f;
    border-radius: 1em;

    font-size: 0.7rem;

    position: absolute;
    top: -1em;
    right: -2em;

    z-index: 1;
    box-shadow: var(--shadow40);

    padding: 0.2em 0.8em;
}