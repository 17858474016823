.container {
    position: relative;
    
    min-width: 100%;
    height: 100%;

    height: fit-content;
}

.lazyload {
    width: 100%;
    height: auto;

    position: relative;
    z-index: 1;
}

.filter {
    position: absolute;

    width: 100%;
    height: 100%;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    z-index: 2;
    
    backdrop-filter: blur(5px);
}

.loader__container {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
}

.loader {
    width: 24px;
    height: 24px;

    border: 2px solid var(--f10);
    border-top-color: var(--f60);
    border-radius: 24px;
}

.image__container {
    width: 100%;
    height: fit-content;

    position: absolute;
    display: flex;

    opacity: 0;
}

.lazyload {
    object-fit: contain;
    height: 250px;
}